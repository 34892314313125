// hooks
import useCloseOnEsc from "hooks/useCloseOnEsc"
// images
import { ReactComponent as Cross } from "assets/images/pages/account/settings/change_pass_window/Cross.svg"

export default ({ closeWindow } : { closeWindow: () => void }) => {
  useCloseOnEsc(closeWindow)
  
  return (
    <div className="change-password-window">
      <div className="bg" onClick={closeWindow} />
      <form className="window">
        <div className="top">
          <div className="title">Change password</div>
          <div className="cross-cont" onClick={closeWindow}><Cross /></div>
        </div>
        <div className="middle">
          <input type="password" placeholder="Current password" />
          <input type="password" placeholder="Enter a new password" />
          <input type="password" placeholder="Re-enter a new password" />
        </div>
        <div className="bottom">
          <span onClick={closeWindow}>Cancel</span>
          <button>Yes</button>
        </div>
      </form>
    </div>
  )
}