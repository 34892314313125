import { create } from 'zustand'

export interface state {
  logged: boolean,
  login: () => void,
  logout: () => void,
  balance: number,
  currency: string,
  username: string,
  email: string,
  password: string,
  daysLeft: number,
  maxDaysLeft: number,
  subscription: string
}

export default create<state>(set => ({
  logged: localStorage.getItem("logged") === "true" ? true : false,
  login: () => {
    localStorage.setItem("logged", "true")
    // set(state => ({...state, logged: true}))
  },
  logout: () => {
    localStorage.setItem("logged", "false")
    // set(state => ({...state, logged: false}))
  },
  balance: 0.001,
  currency: "USDT",
  username: "Markus",
  email: "markus@gmail.com",
  password: "Qwerty123%",
  daysLeft: 32,
  maxDaysLeft: 40,
  subscription: "free"
}))