// react
import { useEffect } from "react"

export default (closeWindowFun: () => void) => {
  useEffect(()=>{
    document.addEventListener('keydown', (e)=>{
      if(e.key === 'Escape'){
        e.preventDefault()
        closeWindowFun()
      }
    })
  }, [])
}