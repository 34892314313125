// react-router-dom
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
// zustand
import useAccount from "store/useAccount"

export default (mustBeLogged: boolean) => {
  const navigate = useNavigate()
  const logged = useAccount(state => state.logged)

  useEffect(()=>{
    if(logged !== mustBeLogged) navigate("/")
    return () => {
    }
  }, [logged, navigate])
}