// zustand
import useAccount from "store/useAccount"

export default () => {
  const balance = useAccount(state => state.balance)
  const currency = useAccount(state => state.currency)

  return (
    <section className="balance-cont">
      <span>Account Balance:</span>
      <div className="balance-currency">
        <div className="balance">{ balance }</div>
        <div className="currency">{ currency }</div>
      </div>
    </section>
  )
}