// react
import { useEffect } from 'react'
// components
import Header from 'components/header/Header'
// pages
import Home from 'pages/home/Home'
import SignUp from 'pages/sign_up/SignUp'
import LogIn from 'pages/log_in/LogIn'
import Account from 'pages/account/Account'
// react-router-dom
import { BrowserRouter, Route, Routes } from "react-router-dom"

export default () => {
  useEffect(()=>{
    if(!localStorage.getItem("logged")) localStorage.setItem("logged", "false")
  }, [])

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/account" element={<Account />} />
      </Routes>
    </BrowserRouter>
  )
}