// react
import { useEffect, useState } from "react"
// components
import SubWindow from "./components/SubWindow"
// images
import { ReactComponent as SubArrowRight } from "assets/images/pages/account/subscription/SubArrowRight.svg"
// zustand
import useAccount from "store/useAccount"

export default () => {
  const daysLeft = useAccount(state => state.daysLeft)
  const maxDaysLeft = useAccount(state => state.maxDaysLeft)
  const subscription = useAccount(state => state.subscription)
  const maxDashoffset = 437 // for stroke
  const [subWindow, setSubWindow] = useState(false)
  const [subWindow_title, setSubWindow_title] = useState("")
  const [subWindow_price, setSubWindow_price] = useState(0)

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting){
        // animate progress ring
        const circle = document.querySelector<SVGCircleElement>("main.account .days-left svg circle")
        if(circle){
          circle.style.transition = "2s"
          circle.style.strokeDashoffset = `${ maxDashoffset - daysLeft * maxDashoffset / maxDaysLeft }`
        }
      }
    })
  })

  const handleWindowOpen = (title: string, price: number) => {
    setSubWindow(true)
    setSubWindow_title(title)
    setSubWindow_price(price)
  }

  useEffect(()=>{
    const targetElement = document.querySelector('main.account .days-left')
    if(targetElement) observer.observe(targetElement)
    // progress ring init settings
    const circle = document.querySelector<SVGCircleElement>("main.account .days-left svg circle")
    if(circle){
      circle.style.strokeDasharray = `${ maxDashoffset }`
      circle.style.strokeDashoffset = `${ maxDashoffset }`
    }
  }, [])

  useEffect(()=>{
    document.body.style.overflowY = subWindow ? "hidden" : "unset"
  }, [subWindow])

  return (
    <section className="cont subscription-cont">
      { subWindow && <SubWindow closeWindow={() => setSubWindow(false)} title={ subWindow_title } price={ subWindow_price } /> }
      <div className="title-cont">
        <div className="title">Active subscribe</div>
      </div>
      <div className="container">
        <div className="cont">
          <div className="days-left">
            <span>{ daysLeft } { daysLeft % 10 === 1 ? "day" : "days" }</span>
            <svg>
              <defs>
                <linearGradient id="paint0_linear_26_3" x1="412.5" y1="-29" x2="403.922" y2="160.572" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#77FFE7"/>
                  <stop offset="1" stopColor="#A9DDA3"/>
                </linearGradient>
              </defs>
              <circle
                strokeWidth="12.6%"
                r="43.7%"
                cx="50%"
                cy="50%"
                stroke="url(#paint0_linear_26_3)"
                strokeLinecap="round"
                fill="none"
              />
            </svg>
          </div>
          <div className="subscriptions">
            <button className={ subscription ===  "free" ? "selected" : "" } onClick={ subscription !== "free" ? () => handleWindowOpen("free", 0) : ()=>{} }>
              <span>Free</span>
              { subscription !== "free" ? <div className="arrows"><SubArrowRight /><SubArrowRight /></div> : null }
            </button>
            <button className={ subscription ===  "base" ? "selected" : "" } onClick={ subscription !== "base" ? () => handleWindowOpen("base", 29) : ()=>{} }>
              <span>Base</span>
              { subscription !== "base" ? <div className="arrows"><SubArrowRight /><SubArrowRight /></div> : null }
            </button>
            <button className={ subscription ===  "pro" ? "selected" : "" } onClick={ subscription !== "pro" ? () => handleWindowOpen("pro", 149) : ()=>{} }>
              <span>Pro</span>
              { subscription !== "pro" ? <div className="arrows"><SubArrowRight /><SubArrowRight /></div> : null }
            </button>
            <button className={ subscription ===  "unlimited" ? "selected" : "" } onClick={ subscription !== "unlimited" ? () => handleWindowOpen("unlimited", 499) : ()=>{} }>
              <span>Unlimited</span>
              { subscription !== "unlimited" ? <div className="arrows"><SubArrowRight /><SubArrowRight /></div> : null }
            </button>
          </div>
        </div>
        <p>*Abandoned the traditional personal account in favor of telegram</p>
      </div>
    </section>
  )
}