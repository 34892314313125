// react
import { useEffect, useState } from "react"
// react-router-dom
import { Link } from "react-router-dom"
// images
import { ReactComponent as Logo } from "assets/images/components/header/Logo.svg"
import { ReactComponent as ArrowDown } from "assets/images/components/header/ArrowDown.svg"
import { ReactComponent as LogOut } from "assets/images/components/header/LogOut.svg"
// components
import LogOutWindow from "./components/logOutWindow"
// zustand
import useAccount from "store/useAccount"

export default () => {
  const [menuOpened, setMenuOpened] = useState(false)

  const userLogged = useAccount(state => state.logged)
  const [logOutWindow, setLogOutWindow] = useState(false)

  const handleLogOutWindowOpen = (e: any) => {
    e.preventDefault()
    setMenuOpened(false)
    setLogOutWindow(true)
  }

  useEffect(()=>{
    // prevent overflow when menu is opened
    document.body.style.overflowY = menuOpened ? "hidden" : "unset"
  }, [menuOpened])

  useEffect(()=>{
    // close menu on outside click
    document.addEventListener("click", (e)=>{
      const header = document.querySelector("header")
      if(!header?.contains(e.target as Node)) setMenuOpened(false)
    })
  }, [])

  return (
    <>
      {/* log out window */}
      { logOutWindow && <LogOutWindow closeWindow={()=>{setLogOutWindow(false)}} /> }
      <header>

        {/* logo */}
        <Link to="/" className="logo"><Logo /></Link>
        
        {/* menu btn */}
        <button className="menu-btn" onClick={() => setMenuOpened(true)}>
          <div/>
          <div/>
          <div/>
        </button>

        {/* nav aka menu */}
        <div className="nav-cont">
          <nav style={{ transform: `translateX(${ menuOpened ? "-100%" : ".8125rem" })` }}>

            <div className="cont top">
              <div className="cont logo">
                <Logo />
                <div>DetectMe</div>
              </div>
              
              {/* menu btn */}
              <button className="menu-btn" onClick={() => setMenuOpened(false)}>
                <div/>
                <div/>
              </button>
            </div>

            <ul>
              <li><a onClick={() => setMenuOpened(false)} href="/#">Home</a></li>
              <li><a onClick={() => setMenuOpened(false)} href="/#advantages">Advantages</a></li>
              <li><a onClick={() => setMenuOpened(false)} href="/#plans">Subscribe</a></li>
              <li className="app-menu-trigger">
                App<ArrowDown />
                <menu>
                  <li><a href="">Download Windows</a></li>
                  <li><a href="">Download Mac Os</a></li>
                </menu>
              </li>
            </ul>
            
            <div className={`cont bottom ${userLogged ? "logged-in" : "logged-out"}`}>
              {
                userLogged ?
                  <>
                    <Link to="/account" className="account" onClick={() => setMenuOpened(false)}>Account</Link>
                    <a href="" className="log-out" onClick={handleLogOutWindowOpen}><LogOut />Log out</a>
                  </>
                :
                  <>
                    <Link to="/log-in" className="log-in" onClick={() => setMenuOpened(false)}>Log in</Link>
                    <Link to="/sign-up" className="sign-up" onClick={() => setMenuOpened(false)}>Sign up</Link>
                  </>
              }
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}