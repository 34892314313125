// components
import BG from "components/BG"
import Hero from "./components/Hero"
import Advantages from "./components/Advantages"
import Security from "./components/Security"
import Plans from "./components/Plans"
import EasyToUse from "./components/EasyToUse"
import Footer from "./components/Footer"

export default () => {
  return (
    <main className="home">
      <BG />
      <Hero />
      <Advantages />
      <Security />
      <Plans />
      <EasyToUse />
      <Footer />
    </main>
  )
}