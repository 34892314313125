// hooks
import useCloseOnEsc from "hooks/useCloseOnEsc"
// images
import { ReactComponent as Cross } from "assets/images/pages/account/subscription/sub_window/Cross.svg"
import { ReactComponent as Check } from "assets/images/pages/account/subscription/sub_window/Check.svg"

export default ({ closeWindow, title, price } : { closeWindow: () => void, title: string, price: number }) => {
  useCloseOnEsc(closeWindow)

  return (
    <div className="sub-window">
      <div className="bg" onClick={closeWindow} />
      <div className="window">
        <div className="top">
          <button className="cross-cont"><Cross /></button>
          <div className="title">{ title }</div>
          <button className="cross-cont" onClick={closeWindow}><Cross /></button>
        </div>
        <div className="middle">
          <div className="price">$___</div>
          {/* <div className="price">${ price }</div> */}
          <div className="per">per month</div>
        </div>
        <div className="bottom">
          <ul>
            <li><Check /><span>100 profiles</span></li>
            <li><Check /><span>5 user</span></li>
            <li className={ title === "free" ? "disabled" : "" }><Check /><span>Cloud sync 128GB</span></li>
            <li className={ title === "free" ? "disabled" : "" }><Check /><span>Extensions</span></li>
            <li className={ title === "free" ? "disabled" : title === "base" ? "disabled" : "" }><Check /><span>Base scripts</span></li>
          </ul>
          <a href=""><span>Subscribe</span>Subscribe</a>
        </div>
      </div>
    </div>
  )
}