// images
import { ReactComponent as Logo } from "assets/images/pages/home/footer/Logo.svg"
import { ReactComponent as Tg } from "assets/images/pages/home/footer/Tg.svg"
import { ReactComponent as Twitter } from "assets/images/pages/home/footer/Twitter.svg"
import { ReactComponent as Git } from "assets/images/pages/home/footer/Git.svg"
import { ReactComponent as S } from "assets/images/pages/home/footer/S.svg"

export default () => {
  return (
    <footer>
      <div className="container">
        <div className="cont">
          <div className="logo">
            <Logo />
            <div>DetectMe</div>
          </div>
          <ul className="links">
            <li>
              <div>STAKEME PRODUCTS</div>
              <ul>
                <li><a href="https://exploreme.pro">Explorer</a></li>
                <li><a href="https://faucetme.pro">Faucet</a></li>
              </ul>
            </li>
            <li>
              <div>SOCIALS</div>
              <ul>
                <li><a href="https://stakeme.pro">Stakeme.pro</a></li>
                <li><a href="https://twitter.com/stakeme_pro">Twitter</a></li>
                <li><a href="https://github.com/stakeme-team">GitHub</a></li>
                <li><a href="https://t.me/stakeme">Community</a></li>
              </ul>
            </li>
            <li>
              <div>CONTACT</div>
              <ul>
                <li><a href="mailto:support@detectme.pro">support@detectme.pro</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div>© 2023 STAKEME COMMUNITY</div>
        <ul>
          <li><a href="https://stakeme.pro"><S /></a></li>
          <li><a href="https://twitter.com/stakeme_pro"><Twitter /></a></li>
          <li><a href="https://github.com/stakeme-team"><Git /></a></li>
          <li><a href="https://t.me/stakeme"><Tg /></a></li>
        </ul>
      </div>
    </footer>
  )
}