// zustand
import useAccount from "store/useAccount"
// hooks
import useCloseOnEsc from "hooks/useCloseOnEsc"

export default ({ closeWindow } : { closeWindow: () => void }) => {
  useCloseOnEsc(closeWindow)

  const logOut = useAccount(state => state.logout)

  const handleLogOut = (e: any) => {
    e.preventDefault()
    logOut()
    window.location.href = "/"
  }

  return (
    <div className="logout-window">
      <div className="bg" />
      <form className="window">
        <div className="top">
          <p>Do you really want to log out?</p>
        </div>
        <div className="bottom">
          <span onClick={closeWindow}>Cancel</span>
          <button onClick={handleLogOut}>Yes</button>
        </div>
      </form>
    </div>
  )
}