// react
import { useEffect } from "react"
// images
import { ReactComponent as Line_1 } from "assets/images/pages/home/advantages/Line_1.svg"
import { ReactComponent as Line_2 } from "assets/images/pages/home/advantages/Line_2.svg"
import { ReactComponent as Line_3 } from "assets/images/pages/home/advantages/Line_3.svg"
import { ReactComponent as Line_4 } from "assets/images/pages/home/advantages/Line_4.svg"

export default () => {
  const paragraphs = [
    "Sync you profile to use on multiple devices. We’ll keep your data safe. Privacy and encryption it’s all about detectme. Cloud size depends on you subscription.",
    "Verified scripts without data leakage. Start to use just clicking one button. We know your demands. Made specially for crypto community.",
    "Access extensions through built in marketplace. All necessary dapps in one place: Wallets, explorers, bridges… etc. Set up your extensions once and use them in all profiles."
  ]

  // Create an observer
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting) typeEffect()
    })
  })

  useEffect(()=>{
    // observe each advantage paragraph
    const targetElements = document.querySelectorAll('section.advantages .advantage')
    targetElements.forEach(targetElement => {
      observer.observe(targetElement)
    })
  }, [])

  // type effect function (animates text & lines)
  const typeEffect = () => {
    const intervalTime = 20
    const lineTime = 1500
    const fixTime = 1000
    document.querySelectorAll('section.advantages .advantage').forEach((advantage, i) => {
      const span = advantage.querySelectorAll('p span')[0] as HTMLSpanElement
      if(!span) return
      let paragraphTransitionDelay = 0
      for(let j=0; j<i; j++) paragraphTransitionDelay += paragraphs[j].length * intervalTime - fixTime * 2 + lineTime
      // animate lines
      if(i){
        const lines = document.querySelectorAll<SVGPathElement>('section.advantages .line')
        lines[(i-1)*2].style.transitionDelay = `${paragraphTransitionDelay - lineTime + fixTime - 500}ms`
        lines[(i-1)*2+1].style.transitionDelay = `${paragraphTransitionDelay - lineTime + fixTime - 500}ms`
        lines[(i-1)*2].classList.add("animated")
        lines[(i-1)*2+1].classList.add("animated")
      }
      // type effect
      const interval = setInterval(()=>{
        setTimeout(()=>{
          if(paragraphs[i].length > span.innerHTML.length) span.innerHTML += paragraphs[i].split("")[span.innerHTML.length]
          else clearInterval(interval)
        }, paragraphTransitionDelay)
      }, intervalTime)
    })
  }

  return (
    <section className="advantages" id="advantages">
      <div className="bg">
        <div className="cols">
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
        <div className="rows">
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
      <div className="wrapper">
        <h2>DetectMe Advantages</h2>
        <div className="cont">
          <div className="advantage">
            <div className="title">Cloud Sync</div>
            <p><span/><span>Sync you profile to use on multiple devices. We’ll keep your data safe. Privacy and encryption it’s all about detectme. Cloud size depends on you subscription.</span></p>
          </div>
          <Line_1 className="line" /> {/* mobile */}
          <Line_3 className="line" /> {/* desktop */}
          <div className="advantage">
            <div className="title">Script automation</div>
            <p><span/><span>Verified scripts without data leakage. Start to use just clicking one button. We know your demands. Made specially for crypto community.</span></p>
          </div>
          <Line_2 className="line" /> {/* mobile */}
          <Line_4 className="line" /> {/* desktop */}
          <div className="advantage">
            <div className="title">Extensions</div>
            <p><span/><span>Access extensions through built in marketplace. All necessary dapps in one place: Wallets, explorers, bridges… etc. Set up your extensions once and use them in all profiles.</span></p>
          </div>
        </div>
      </div>
    </section>
  )
}