// zustand
import useAccount from "store/useAccount"
// hooks
import useRedirect from "hooks/useRedirect"
// components
import Balance from "./components/balance/Balance"
import Settings from "./components/settings/Settings"
import Subscription from "./components/subscription/Subscription"

export default () => {
  // redirect if not logged in
  const logged = useAccount(state => state.logged)
  useRedirect(true)

  return logged ?
    <main className="account">
      <div className="wrapper">
        <Balance />
        <Settings />
        <Subscription />
      </div>
    </main>
  : null
}