// react
import { useState } from "react"
// zustand
import useAccount from "store/useAccount"
// hooks
import useRedirect from "hooks/useRedirect"
// components
import BG from "components/BG"

export default () => {
  // redirect if logged in
  const logged = useAccount(state => state.logged)
  useRedirect(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  // DEMO
  const logIn = useAccount(state => state.login)

  const handleLogIn = (e: any) => {
    e.preventDefault()
    logIn()
    window.location.href = "/account"
  }
  // DEMO

  return logged ? null :
    <main className="log-in">
      <BG />
      <form>
        <h3>Authorization</h3>
        <input type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} />
        <input type="password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} />
        <a href="" className="forgot">forget password?</a>
        <button onClick={handleLogIn}>Log in</button>
        <a href="/sign-up" className="create">or create account</a>
      </form>
    </main>
}