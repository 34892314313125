// react
import { useState } from "react"

export default () => {
  const [periodSelected, setPeriodSelected] = useState(0)

  return (
    <section className="plans" id="plans">
      <h2>Plans</h2>
      <p>*Pay in one click. All payments are secured.<br/><span>Just pay and use DetectME</span></p>
      <div className="period">
        <div className="pill-cont">
          <div className="pill" style={{ transform: `translateX(${ periodSelected ? "100%" : "0" })` }} />
        </div>
        <button className={!periodSelected ? "selected" : ""} onClick={()=>{setPeriodSelected(0)}}>Monthly</button>
        <button className={periodSelected ? "selected" : ""} onClick={()=>{setPeriodSelected(1)}}>Yearly</button>
      </div>
      <div className="plans">
        <div className="cont">
          <div className="card">
            <div className="title">Free</div>
            <div className="price">$___</div>
            {/* <div className="price">$0</div> */}
            <div className="per">per { periodSelected ? "year" : "month" }</div>
            <p>Just take it and use it. Everything is already in the free version</p>
          </div>
          <div className="details">
            <ul>
              <li>10 profiles</li>
              <li>1 user</li>
              <li className="disabled">Cloud sync</li>
              <li className="disabled">Extensions</li>
              <li className="disabled">Scripts</li>
            </ul>
            <a href="">Subscribe</a>
          </div>
        </div>
        <div className="cont">
          <div className="card">
            <div className="title">Base</div>
            <div className="price">$___</div>
            {/* <div className="price">${ periodSelected ? "279" : "29" }</div> */}
            <div className="per">per { periodSelected ? "year" : "month" }</div>
            <p>All the features you need to keep your personal safe, accessible and easy to use.</p>
          </div>
          <div className="details">
            <ul>
              <li>10 profiles</li>
              <li>1 user</li>
              <li>Cloud sync</li>
              <li>Extensions</li>
              <li className="disabled">Scripts</li>
            </ul>
            <a href="">Subscribe</a>
          </div>
        </div>
        <div className="cont">
          <div className="card">
            <div className="title">Pro</div>
            <div className="price">$___</div>
            {/* <div className="price">${ periodSelected ? "1429" : "149" }</div> */}
            <div className="per">per { periodSelected ? "year" : "month" }</div>
            <p>More profiles. Special offers from developers available only from this subscription</p>
          </div>
          <div className="details">
            <ul>
              <li>10 profiles</li>
              <li>1 user</li>
              <li>Cloud sync</li>
              <li>Extensions</li>
              <li>Scripts</li>
            </ul>
            <a href="">Subscribe</a>
          </div>
        </div>
        <div className="cont">
          <div className="card">
            <div className="title">Unlimited</div>
            <div className="price">$___</div>
            {/* <div className="price">${ periodSelected ? "4799" : "499" }</div> */}
            <div className="per">per { periodSelected ? "year" : "month" }</div>
            <p>Special subscription for users who need everything at once</p>
          </div>
          <div className="details">
            <ul>
              <li>10 profiles</li>
              <li>1 user</li>
              <li>Cloud sync</li>
              <li>Extensions</li>
              <li>Scripts</li>
            </ul>
            <a href="">Subscribe</a>
          </div>
        </div>
      </div>
    </section>
  )
}