// react
import { useEffect, useState } from "react"
// zustand
import useAccount from "store/useAccount"
// hooks
import useRedirect from "hooks/useRedirect"
// components
import BG from "components/BG"

export default () => {
  // redirect if logged in
  const logged = useAccount(state => state.logged)
  useRedirect(false)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [conditions, setConditions] = useState(0)
  const [progressConditions, setProgressConditions] = useState(0)
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const upperCaseRegex = /[A-Z]/
  const lowerCaseRegex = /[a-z]/
  const lettersAndNumbersRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/
  const specialSymbolsRegex = /[\W_]/

  // DEMO
  const logIn = useAccount(state => state.login)

  const handleLogIn = (e: any) => {
    e.preventDefault()
    logIn()
    window.location.href = "/account"
  }
  // DEMO

  // track account requirements completion
  useEffect(()=>{
    let cond = 0
    if(emailRegex.test(email)) cond++
    if(password.length >= 8) cond++
    if(lettersAndNumbersRegex.test(password)) cond++
    if(upperCaseRegex.test(password) && lowerCaseRegex.test(password)) cond++
    if(specialSymbolsRegex.test(password)) cond++
    if(password === repeatPassword) cond++
    setConditions(cond)
  }, [email, password, repeatPassword])

  // track password requirements completion
  useEffect(()=>{
    let cond = 0
    if(password.length >= 8) cond++
    if(lettersAndNumbersRegex.test(password)) cond++
    if(upperCaseRegex.test(password) && lowerCaseRegex.test(password)) cond++
    if(specialSymbolsRegex.test(password)) cond++
    setProgressConditions(cond)
  }, [password])

  return logged ? null :
    <main className="sign-up">
      <BG />
      <form>
        <h3>Registration</h3>
        <input type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} />
        <input type="password" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} />
        <div className="safety">
          <div className="title">Password safety</div>
          <div className="progress">
            <span style={{ backgroundColor: progressConditions >= 2 ? "#2BDC67" : "#fff" }} />
            <span style={{ backgroundColor: progressConditions >= 3 ? "#2BDC67" : "#fff" }} />
            <span style={{ backgroundColor: progressConditions >= 4 ? "#2BDC67" : "#fff" }} />
          </div>
          <div className="details">
            <div><span style={{ backgroundColor: password.length >= 8 ? "#2BDC67" : "#fff" }} />minimum 8 characters</div>
            <div><span style={{ backgroundColor: lettersAndNumbersRegex.test(password) ? "#2BDC67" : "#fff" }} />letters and numbers</div>
            <div><span style={{ backgroundColor: upperCaseRegex.test(password) && lowerCaseRegex.test(password) ? "#2BDC67" : "#fff" }} />upper and lower case</div>
            <div><span style={{ backgroundColor: specialSymbolsRegex.test(password) ? "#2BDC67" : "#fff" }} />special symbols</div>
          </div>
        </div>
        <input type="password" placeholder="Repeat password" onChange={(e)=>{setRepeatPassword(e.target.value)}} />
        <div className="terms"><input type="checkbox" id="termsCheckbox" required /><label htmlFor="termsCheckbox">By checking this box, you agree to our <a href="">Term of Service</a> and <a href="">Privacy Policy</a></label></div>
        <button disabled={ conditions === 6 ? false : true } onClick={handleLogIn}>Sign up</button>
      </form>
    </main>
}