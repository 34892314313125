import { useEffect, useState } from "react"
// images
import { ReactComponent as ArrowRight } from "assets/images/pages/home/easy_to_use/ArrowRight.svg"
import { ReactComponent as ArrowDown } from "assets/images/pages/home/easy_to_use/ArrowDown.svg"
import { ReactComponent as Windows } from "assets/images/pages/home/easy_to_use/Windows.svg"
import { ReactComponent as Apple } from "assets/images/pages/home/easy_to_use/Apple.svg"
import  S1 from "assets/images/pages/home/easy_to_use/screenshots/S1.png"
import  S2 from "assets/images/pages/home/easy_to_use/screenshots/S2.png"
import  S3 from "assets/images/pages/home/easy_to_use/screenshots/S3.png"
import  S4 from "assets/images/pages/home/easy_to_use/screenshots/S4.png"
import  S5 from "assets/images/pages/home/easy_to_use/screenshots/S5.png"

export default () => {
  const [screenshotNum, setScreenshotNum] = useState(0)
  const [isIntervalRunning, setIsIntervalRunning] = useState(true)

  useEffect(() => {
    let interval = null as any

    if(isIntervalRunning){
      interval = setInterval(() => {
        setScreenshotNum((prevNum) => (prevNum === 4 ? 0 : prevNum + 1))
      }, 3000)
    }

    return () => clearInterval(interval)
  }, [isIntervalRunning])

  const handlePauseClick = () => {
    setIsIntervalRunning(false)
    // Resume the interval after 7 seconds
    setTimeout(() => {
      setIsIntervalRunning(true)
    }, 7000)
  }

  return (
    <section className="easy-to-use">
      <h2>Easy to use</h2>
      <div className="cont">
        <div className="carousel">
          <div className="screenshot" style={{
            backgroundImage: `url(${
              screenshotNum === 0 ? S1
              : screenshotNum === 1 ? S2
              : screenshotNum === 2 ? S3
              : screenshotNum === 3 ? S4
              : screenshotNum === 4 ? S5
              : ""
            })`
          }} />
          <nav>
            <button className={screenshotNum !== 0 ? "btn" : "btn disabled"} disabled={screenshotNum === 0 ? true : false } onClick={()=>{handlePauseClick(); setScreenshotNum(screenshotNum-1)}}><ArrowRight /></button>
            <button className={screenshotNum !== 4 ? "btn" : "btn disabled"} disabled={screenshotNum === 4 ? true : false } onClick={()=>{handlePauseClick(); setScreenshotNum(screenshotNum+1)}}><ArrowRight /></button>
          </nav>
        </div>
        <ul>
          <li>
            <div className="num">1</div>
            <div>
              <div className="title">Download app</div>
              <p>Download the Detect me app. It was tested by professional developers and meet all the safety requirements.</p>
            </div>
          </li>
          <li>
            <div className="num">2</div>
            <div>
              <div className="title">Select subscribe</div>
              <p>You can change or cancel you subscription any time in case you don’t need it.</p>
            </div>
          </li>
          <li>
            <div className="num">3</div>
            <div>
              <div className="title">Configure</div>
              <p>Our team is mainly focused on crypto community so you can configure the app for your needs.</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="buttons">
        <a href=""><Windows />Download for Windows<ArrowDown /></a>
        <a href=""><Apple />Download for macOS<ArrowDown /></a>
      </div>
    </section>
  )
}