// images
import { ReactComponent as BG_Mobile } from "assets/images/components/bg/BG_Mobile.svg"
// import { ReactComponent as BG_Desktop } from "assets/images/components/bg/BG_Desktop.svg"

export default () => {
  return (
    <div className="bg-cont">
      <BG_Mobile />
      {/* <BG_Desktop /> */}
    </div>
  )
}