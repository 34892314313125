// react
import { useState } from "react"
// components
import ChangePasswordWindow from "./components/ChangePassword"
// images
import { ReactComponent as PFP } from "assets/images/pages/account/settings/PFP.svg"
import { ReactComponent as PassArrowRight } from "assets/images/pages/account/settings/PassArrowRight.svg"
// zustand
import useAccount from "store/useAccount"

export default () => {
  const email = useAccount(state => state.email)
  const username = useAccount(state => state.username)

  const [editUsername, setEditUsername] = useState(username)

  const [ changePasswordWindow, setChangePasswordWindow ] = useState(false)

  const handleChangePasswordWindowOpen = () => {
    setChangePasswordWindow(true)
  }

  return (
    <section className="cont settings-cont">
      { changePasswordWindow && <ChangePasswordWindow closeWindow={()=>{setChangePasswordWindow(false)}} /> }
      <div className="title">User settings</div>
      <form className="user-info">
        <PFP className="pfp" />
        <div className="email">{ email }</div>
        <input type="text" placeholder="Name" defaultValue={ username } onChange={(e)=>{setEditUsername(e.target.value)}} />
        <div className="change-password" onClick={handleChangePasswordWindowOpen}>
          <span>Change password</span>
          <PassArrowRight />
        </div>
        <button>Apply changes</button>
      </form>
    </section>
  )
}