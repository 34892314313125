import { useEffect } from "react"
// images
import { ReactComponent as Lock } from "assets/images/pages/home/security/Lock.svg"
import { ReactComponent as SecurityBG } from "assets/images/pages/home/security/SecurityBG.svg"

export default () => {
  const text = [
    "Disable profile synchronization any time",
    "Advance data encryption",
    "24/7 tech support",
    "Regular updates"
  ]

  const typeEffectObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting) typeEffect()
    })
  })

  const highlightEffectObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting){
        document.querySelectorAll('section.security .lock .highlight').forEach(highlight => {
          highlight.classList.add('animated')
        })
      }
    })
  })

  useEffect(()=>{
    // type effect
    document.querySelectorAll('section.security ul li').forEach(targetElement => {
      typeEffectObserver.observe(targetElement)
    })
    // highlight effect
    const targetElement = document.querySelector('section.security .lock')
    if(targetElement) highlightEffectObserver.observe(targetElement)
  }, [])

  const typeEffect = () => {
    const intervalTime = 70
    document.querySelectorAll('section.security ul li').forEach((li, i) => {
      let timeToWait = -15000
      for(let j=0; j<i; j++){
        timeToWait += text[j].length * intervalTime + intervalTime
      }
      setTimeout(()=>{
        const interval = setInterval(()=>{
          if(li.querySelectorAll('span')[0].innerHTML.length < text[i].length) li.querySelectorAll('span')[0].innerHTML += text[i][li.querySelectorAll('span')[0].innerHTML.length]
          else clearInterval(interval)
        }, intervalTime)
      }, timeToWait)
    })
  }

  return (
    <section className="security">
      <div className="security-bg-cont">
        <SecurityBG className="bg" />
      </div>
      <div className="content">
        <h2>Security</h2>
        <p>All data is stored encrypted and private. The only one person can access is you. </p>
        <ul>
          <li><div><span></span><span>Disable profile synchronization any time</span></div></li>
          <li><div><span></span><span>Advance data encryption</span></div></li>
          <li><div><span></span><span>24/7 tech support</span></div></li>
          <li><div><span></span><span>Regular updates</span></div></li>
        </ul>
        <div className="lock">
          <div className="highlight" />
          {/* <div className="highlight" /> */}
          <Lock />
        </div>
      </div>
    </section>
  )
}