// react
import { useEffect } from "react"
// images
import { ReactComponent as DownloadIcon } from "assets/images/pages/home/hero/Download.svg"
import { ReactComponent as ScrollDownArrow } from "assets/images/pages/home/hero/ScrollDownArrow.svg"

export default () => {
  const text = ["safest", "anti-detect", "browser", "for", "crypto", "community"]

  useEffect(()=>{
    // hide scroll arrow on scroll
    window.addEventListener("scroll", ()=>{
      const scrollArrow = document.querySelector('section.hero .scroll-arrow-down') as SVGAElement
      scrollArrow.style.opacity = window.scrollY > 100 ? "0" : "1"
    })

    // type effect
    document.querySelectorAll('section.hero ul li').forEach((li, i) => {
      const intervalTime = 40
      let timeToWait = 0
      for(let j=0; j<i; j++){
        timeToWait += text[j].length * intervalTime + intervalTime
      }
      setTimeout(()=>{
        const interval = setInterval(()=>{
          if(li.querySelectorAll('span')[0].innerHTML.length < text[i].length) li.querySelectorAll('span')[0].innerHTML += text[i][li.querySelectorAll('span')[0].innerHTML.length]
          else clearInterval(interval)
        }, intervalTime)
      }, timeToWait)
    })
  }, [])

  return (
    <section className="hero">
      <div className="beta">BETA TESTING</div>
      <div className="container">
        <div className="cont">
          <h1>Detectme</h1>
          <ul>
            <li><span /><span>safest</span></li>
            <li><span /><span>anti-detect</span></li>
            <li><span /><span>browser</span></li>
            <li><span /><span>for</span></li>
            <li><span /><span>crypto</span></li>
            <li><span /><span>community</span></li>
          </ul>
          <h1>Browser</h1>
        </div>
        <a href=""><DownloadIcon />Free download</a>
      </div>
      <a href="#advantages" className="scroll-arrow-down">
        <ScrollDownArrow />
      </a>
    </section>
  )
}